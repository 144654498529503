import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import style from "./LocationPinWithIndex.module.css";

const cx = classNames.bind(style);

const LocationPinWithIndex = ({ index, isBrandStore = false }) => (
  <div
    className={cx(
      style.locationPinWithIndex,
      isBrandStore && style.locationPinWithIndexBlue
    )}
  >
    <span className={cx(style.locationPinText, isBrandStore && style.blue)}>
      {index + 1}
    </span>
  </div>
);

LocationPinWithIndex.propTypes = {
  isBrandStore: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

export default LocationPinWithIndex;
