import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import classNames from "classnames";

import { isMobile } from "config";
import { isDarkMode } from "utils";
import DataFetcher from "./DataFetcher";
import ProductInfo from "./ProductInfo";
import OnlineStores from "./OnlineStores";
import LocalStores from "./LocalStores";

import style from "./App.module.css";
import "style/react-tabs.css";

if (isDarkMode) {
  import("style/react-tabsDark.css").then();
}

const cx = classNames.bind(style);

const App = () => {
  const [fetchedData, setFetchedData] = useState({});
  const [selectedTabIndex, setSelectedTabIndex] = useState(3);

  const {
    isLoadingComplete = false,
    localStores = [],
    modelInfo = {},
    distanceFromNearestStore,
    onlineStores = [],
  } = fetchedData;

  const hasOnlineStores = onlineStores.length;
  const hasLocalStores = localStores.length || distanceFromNearestStore;
  const hasStores = hasOnlineStores || hasLocalStores;

  useEffect(() => {
    const getDefaultTabIndex = () => {
      if (!isLoadingComplete) return 3;
      if (hasOnlineStores) return 0;
      if (hasLocalStores) return 1;
      return 2;
    };
    setSelectedTabIndex(getDefaultTabIndex());
  }, [hasLocalStores, hasOnlineStores, isLoadingComplete]);

  return (
    <div className={style.app}>
      {!isLoadingComplete && (
        <DataFetcher setFetchedData={setFetchedData} isInitialFetch />
      )}
      <ProductInfo modelInfo={modelInfo} />
      <Tabs
        selectedIndex={selectedTabIndex}
        onSelect={(tabIndex) => setSelectedTabIndex(tabIndex)}
      >
        <TabList
          className={cx(
            isMobile ? "react-tabs__tab-list-mobile" : "react-tabs__tab-list"
          )}
        >
          <Tab
            className={cx(
              isMobile ? "react-tabs__tab-mobile" : "react-tabs__tab"
            )}
            data-omni-type="microsite_buyOnline"
            data-omni={`buy online|;${modelInfo.modelCode}|${modelInfo.model}`}
            disabled={isLoadingComplete && !hasStores}
          >
            Kup online
          </Tab>
          <Tab
            className={cx(
              isMobile ? "react-tabs__tab-mobile" : "react-tabs__tab"
            )}
            data-omni-type="microsite_buyOnline"
            data-omni={`buy in store|;${modelInfo.modelCode}|${modelInfo.model}`}
            disabled={isLoadingComplete && !hasStores}
          >
            Znajdź sklep
          </Tab>
          <Tab disabled />
          <Tab disabled />
        </TabList>
        <TabPanel>
          {isLoadingComplete && hasStores && (
            <OnlineStores
              modelInfo={modelInfo}
              onlineStores={onlineStores}
              switchTab={() => setSelectedTabIndex(1)}
            />
          )}
        </TabPanel>
        <TabPanel className="react-tabs__tab--no-scroll">
          {isLoadingComplete && hasStores && (
            <LocalStores
              distanceFromNearestStore={distanceFromNearestStore}
              localStores={localStores}
              modelInfo={modelInfo}
              switchTab={() => setSelectedTabIndex(0)}
            />
          )}
        </TabPanel>
        <TabPanel>
          <div
            className={cx(
              isMobile ? style.notFoundMobile : style.notFoundDesktop
            )}
          >
            Przepraszamy, na chwilę obecną nie mamy informacji o dostępności
            poszukiwanego przez Ciebie produktu zarówno w sklepach online jak i
            stacjonarnych.
          </div>
        </TabPanel>
        <TabPanel>
          <h3 className={style.loading}>WCZYTYWANIE...</h3>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default App;
