/* eslint-disable no-param-reassign */
import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Scrollbar } from "react-scrollbars-custom";
import { isSafari } from "utils";
import { isMobile } from "config";
import LocalRetailer from "./RetailerList/LocalRetailer";

import overlayStyle from "./Overlay.module.css";
import style from "./RetailerList.module.css";

const RetailerList = ({
  filters,
  handleRetailerPick,
  isMarkerClicked,
  localStores,
  modelInfo,
  overlayActive,
  pickedStoreId,
  setMarkerClicked,
  setPickedStoreId,
  setPickedStoreLocation,
  usingAutoLocation,
}) => {
  const [scrollBarStyle, setScrollBarStyle] = useState(
    isSafari && isMobile
      ? {
          height: `${window.innerHeight - 290}px`,
          width: "100vw",
        }
      : {
          height: `${window.innerHeight - 290}px`,
        }
  );
  const filtersActive = filters.length > 0;
  let elementToScrollTo = {};
  if (pickedStoreId && isMarkerClicked) {
    elementToScrollTo = {
      latitude: parseFloat(pickedStoreId.split("_")[0], 0),
      longitude: parseFloat(pickedStoreId.split("_")[1], 0),
      retailerName: pickedStoreId.split("_")[2],
    };
    setMarkerClicked(false);
  }

  let listStyle = `${style.listContainer}`;
  if (overlayActive) {
    listStyle += ` ${overlayStyle.overlaidElement} ${overlayStyle.overlaidScroll}`;
  }

  const handleRetailerClick = (retailer) => {
    setPickedStoreId(
      `${retailer.latitude}_${retailer.longitude}_${retailer.retailerName}`
    );
    setPickedStoreLocation({
      lat: retailer.latitude,
      lng: retailer.longitude,
    });
    if (isMobile) {
      handleRetailerPick(retailer);
    }
  };

  const scrollbar = useRef(null);

  useEffect(() => {
    if (usingAutoLocation && !isMobile)
      setScrollBarStyle((prevState) =>
        Object.assign(prevState, {
          height: `${window.innerHeight - 261}px`,
        })
      );
  }, [usingAutoLocation]);

  return (
    <div className={`custom-scroll ${listStyle}`}>
      <Scrollbar noDefaultStyles ref={scrollbar} style={scrollBarStyle}>
        {localStores.map((retailer, index) => {
          if (Object.keys(elementToScrollTo).length > 0) {
            if (elementToScrollTo.latitude === retailer.latitude) {
              const indexOfTrue = [...scrollbar.current.contentElement.children]
                .map(
                  /* eslint-disable no-shadow, eqeqeq */
                  (element) =>
                    element.children[0].children[0].children[0].children[0]
                      .textContent ==
                    localStores.find(
                      (element) =>
                        `${element.latitude}_${element.longitude}_${element.retailerName}` ===
                        pickedStoreId
                    ).pinIndex +
                      1
                  /* eslint-enable no-shadow, eqeqeq */
                )
                .findIndex((element) => element);
              const offTop =
                scrollbar.current &&
                scrollbar.current.contentElement.children[indexOfTrue]
                  .offsetTop;
              if (scrollbar && scrollbar.current)
                scrollbar.current.scrollTop = offTop;
            }
          }

          return (
            (!filtersActive || filters.indexOf(retailer.retailerId) >= 0) && (
              <LocalRetailer
                handleClick={() => handleRetailerClick(retailer)}
                index={index}
                isActive={
                  pickedStoreId ===
                  `${retailer.latitude}_${retailer.longitude}_${retailer.retailerName}`
                }
                key={`${retailer.latitude}_${retailer.longitude}_${retailer.retailerName}`}
                modelInfo={modelInfo}
                retailer={retailer}
              />
            )
          );
        })}
        {overlayActive && (
          <div className={`${overlayStyle.overlay} ${overlayStyle.list}`} />
        )}
      </Scrollbar>
    </div>
  );
};

RetailerList.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string),
  handleRetailerPick: PropTypes.func.isRequired,
  isMarkerClicked: PropTypes.bool.isRequired,
  localStores: PropTypes.arrayOf(PropTypes.object),
  modelInfo: PropTypes.shape({
    model: PropTypes.string,
    modelCode: PropTypes.string,
  }).isRequired,
  overlayActive: PropTypes.bool,
  pickedStoreId: PropTypes.string,
  setMarkerClicked: PropTypes.func.isRequired,
  setPickedStoreId: PropTypes.func.isRequired,
  setPickedStoreLocation: PropTypes.func.isRequired,
  usingAutoLocation: PropTypes.bool,
};

export default RetailerList;
