import b1 from "./map-clusters/b1.png";
import b2 from "./map-clusters/b2.png";
import b3 from "./map-clusters/b3.png";
import b4 from "./map-clusters/b4.png";
import b5 from "./map-clusters/b5.png";

import m1 from "./map-clusters/m1.png";
import m2 from "./map-clusters/m2.png";
import m3 from "./map-clusters/m3.png";
import m4 from "./map-clusters/m4.png";
import m5 from "./map-clusters/m5.png";

import locationPinBlack from "./location_pin_black.png";
import locationPinBlue from "./location_pin_blue.png";
import locationPinWhite from "./location_pin_white.png";
import locationPinWhiteBlue from "./location_pin_white_blue.png";

const MAP_ICONS = {
  CLUSTERS: {
    b1,
    b2,
    b3,
    b4,
    b5,
    m1,
    m2,
    m3,
    m4,
    m5,
  },
  PINS: {
    black: locationPinBlack,
    blue: locationPinBlue,
    white: locationPinWhite,
    whiteBlue: locationPinWhiteBlue,
  },
};

export default MAP_ICONS;
