import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as AvailableIcon } from "assets/icons/available.svg";
import { Scrollbar } from "react-scrollbars-custom";
import { isIE11, isSafari } from "utils";
import { isMobile } from "config";
import ProductUnavailable from "./ProductUnavailable";
import styleNormal from "./OnlineStores.module.css";
import styleIE11 from "./OnlineStoresIE11.module.css";

const style = isIE11 ? styleIE11 : styleNormal;

const OnlineRetailer = ({
  modelInfo: { model, modelCode },
  store: { displayName, logoUrl, productLink, name },
}) => {
  return (
    <a
      className={style.rowContainer}
      data-omni-type="microsite_buyOnline"
      data-omni={`buy online:${name}|;${modelCode}|${model}`}
      href={productLink}
      // eslint-disable-next-line react/jsx-no-target-blank
      target="_blank"
    >
      <div className={style.item}>
        <img src={logoUrl} alt={`${displayName} logo`} />
      </div>
      <div className={style.item}>
        <AvailableIcon />
        <span>Produkt dostępny</span>
      </div>
    </a>
  );
};

OnlineRetailer.propTypes = {
  modelInfo: PropTypes.shape({
    model: PropTypes.string,
    modelCode: PropTypes.string,
  }).isRequired,
  store: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    productLink: PropTypes.string.isRequired,
  }),
};

const OnlineStores = ({ modelInfo, onlineStores = [], switchTab }) => {
  let scrollBarStyle = {
    height: `${window.innerHeight - 185}px`,
  };

  if (isSafari) {
    scrollBarStyle = {
      height: `${window.innerHeight - 185}px`,
    };
    if (isMobile) {
      scrollBarStyle = {
        ...scrollBarStyle,

        width: "100vw",
      };
    }
  }

  return (
    <div className={style.listContainer}>
      <Scrollbar noDefaultStyles style={scrollBarStyle}>
        {onlineStores.length ? (
          onlineStores.map((store) => (
            <OnlineRetailer
              key={store.displayName}
              modelInfo={modelInfo}
              store={store}
            />
          ))
        ) : (
          <ProductUnavailable
            storeType="online"
            switchTab={switchTab}
            /* FIXME trackers
            data-omni-type="microsite_buyOnline"
            data-omni={`buy in store|;${modelInfo.modelCode}|${modelInfo.model}`}
            */
          />
        )}
      </Scrollbar>
    </div>
  );
};

OnlineStores.propTypes = {
  modelInfo: PropTypes.shape({
    model: PropTypes.string,
    modelCode: PropTypes.string,
  }).isRequired,
  onlineStores: PropTypes.arrayOf(PropTypes.object).isRequired,
  switchTab: PropTypes.func.isRequired,
};

export default OnlineStores;
