import React from "react";
import PropTypes from "prop-types";
import { Marker } from "@react-google-maps/api";
import { defaultLocation } from "config";

// FIXME use SVG file from assets
const svg = [
  '<?xml version="1.0"?>',
  '<svg width="16px" height="16px" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"><defs><style>.cls-1,.cls-2{fill:#00b3e2;}.cls-1{opacity:0.2;}.cls-2{opacity:0.7;}</style></defs><title>Artboard 1</title><circle class="cls-1" cx="14" cy="14" r="13"/><circle class="cls-2" cx="14" cy="14" r="10"/><circle class="cls-2" cx="14" cy="14" r="6"/></svg>',
].join("\n");

const LocalMarker = ({ location: { lat, lng } }) => {
  if (lat === defaultLocation.lat && lng === defaultLocation.lng) return null;

  return (
    <Marker
      options={{
        icon: {
          url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
        },
        optimized: false,
      }}
      position={{ lat, lng }}
    />
  );
};

LocalMarker.propTypes = {
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
};

export default LocalMarker;
