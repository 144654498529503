import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LocationIcon } from "assets/icons/location_outline.svg";
import { ReactComponent as OnlineIcon } from "assets/icons/online_outline.svg";
import style from "./ProductUnavailableInGivenRange.module.css";

const ProductUnavailableInGivenRange = ({
  searchNearby,
  switchTab,
  /* FIXME trackers
  ...dataOmniAttributes
  */
}) => (
  <div className={style.unavailable}>
    <span className={style.infoText}>
      Niestety nie udało nam się znaleźć sklepów w podanej przez Ciebie
      lokalizacji.
    </span>
    <button
      className={style.findOtherStoreButton}
      type="button"
      onClick={switchTab}
      /* FIXME trackers
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...dataOmniAttributes}
      */
    >
      <div className={style.findOtherStore}>
        <OnlineIcon />
        <span>KUP ONLINE</span>
      </div>
    </button>
    <button
      className={style.findOtherStoreButton}
      type="button"
      onClick={searchNearby}
    >
      <div className={style.findOtherStore}>
        <LocationIcon />
        <span>ZNAJDŹ NAJBLIŻSZY SKLEP</span>
      </div>
    </button>
  </div>
);

ProductUnavailableInGivenRange.propTypes = {
  searchNearby: PropTypes.func.isRequired,
  switchTab: PropTypes.func.isRequired,
};

export default ProductUnavailableInGivenRange;
