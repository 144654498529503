// test examples
// const pattern1Example =  '1:09:00:21:00,2:09:00:22:00,3:09:00:22:00,4:09:00:22:00,5:09:00:22:00,6:09:00:22:00,7:09:00:22:00';
// const pattern2Example = 'pon.-pt. 9:00 - 22:00 sob. 10:00 - 22:00 niedz. 10:00 - 21:00';
// const pattern3Example = 'Mon-Fri 10:00 - 22:00, Sun 10:00 - 22:00, Sat 10:00 - 20:00';
// const pattern3Example_invalidFormat = 'Mon-Fri 10:004279sfw , Sun 10:00 - 22:00, Sat 10:00 - 20:00';
// const pattern4Example = '09:00-21:00';
// const pattern5Example = '{"1":"08:00-22:00","2":"08:00-22:00","3":"08:00-22:00","4":"08:00-22:00","5":"08:00-22:00","6":"08:00-22:00","7":"Nieczynne"}';

// patterns regExp
const patterns = {
  pattern1: /\d:\d\d:\d\d:\d\d:\d\d,/,
  pattern2: /pon.-pt./,
  pattern3: /Mon-Fri/,
  pattern4: /^\d\d:\d\d-\d\d:\d\d/,
  pattern5: /^{"\d/,
};

const parseStoreHours = (inputHours) => {
  if (!inputHours || typeof inputHours !== "string") return null;
  // handle additional formatting
  const hoursRaw = inputHours.replace(/\s(?<day>\d):/g, " 0$<day>:");

  const parsedHours = { 1: "", 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" };

  if (!hoursRaw) return parsedHours;

  // detect and assign pattern key
  let patternKey = "";
  // eslint-disable-next-line no-restricted-syntax
  for (const key in patterns) {
    if (patterns[key].test(hoursRaw)) {
      patternKey = key;
      break;
    }
  }
  if (!patternKey) {
    // console.log("hours pattern not found - ", hoursRaw);
    return parsedHours;
  }

  // handle specific pattern cases
  try {
    switch (patternKey) {
      case "pattern1":
        // eslint-disable-next-line no-case-declarations
        const daysSplit = hoursRaw.split(",");
        for (let i = 0; i < daysSplit.length; i += 1) {
          const d = daysSplit[i];
          const dayNum = d.charAt(0);
          const hoursSplit = d.split(":");
          hoursSplit.shift();
          const dayHours = `${hoursSplit[0]}:${hoursSplit[1]}-${hoursSplit[2]}:${hoursSplit[3]}`;
          parsedHours[dayNum] = dayHours;
        }
        break;
      case "pattern2":
        // eslint-disable-next-line no-case-declarations
        const workDaysRes = hoursRaw.match(
          /pon.-pt.\s*(\d*\d.\d\d\s*-\s*\d*\d.\d\d)/
        );
        // eslint-disable-next-line no-case-declarations
        const workDaysHours = workDaysRes[1].trim();
        // eslint-disable-next-line no-restricted-syntax
        for (const day in parsedHours) {
          if (day >= 1 && day <= 5) {
            parsedHours[day] = workDaysHours.trim();
          }
        }
        // eslint-disable-next-line no-case-declarations
        const satHours = hoursRaw.match(
          /sob.\s*(\d*\d.\d\d\s*-\s*\d*\d.\d\d)/
        )[1];
        parsedHours[6] = satHours.trim();
        // eslint-disable-next-line no-case-declarations
        const sunHours = hoursRaw.match(
          /niedz.\s*(\d*\d.\d\d\s*-\s*\d*\d.\d\d)/
        )[1];
        parsedHours[7] = sunHours.trim();
        break;
      case "pattern3":
        // eslint-disable-next-line no-case-declarations
        const p3WorkDaysHours = hoursRaw.match(
          /Mon-Fri (\d\d:\d\d - \d\d:\d\d)/
        )[1];
        // eslint-disable-next-line no-restricted-syntax
        for (const day in parsedHours) {
          if (day >= 1 && day <= 5) {
            parsedHours[day] = p3WorkDaysHours.trim();
          }
        }
        // eslint-disable-next-line no-case-declarations
        const p3WeekendHours = hoursRaw.match(
          /Sun (\d\d:\d\d - \d\d:\d\d), Sat (\d\d:\d\d - \d\d:\d\d)/
        );
        parsedHours[6] = p3WeekendHours[1].trim();
        parsedHours[7] = p3WeekendHours[2].trim();
        break;
      case "pattern4":
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const day in parsedHours) {
          parsedHours[day] = hoursRaw;
        }
        break;
      case "pattern5":
        return JSON.parse(hoursRaw);
      default:
        return parsedHours;
    }
  } catch (e) {
    return parsedHours;
  }

  return parsedHours;
};

export default parseStoreHours;

// // test
// parseStoreHours(pattern5Example);
