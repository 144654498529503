import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isMobile } from "config";
import { isDarkMode } from "utils";
import styleNormal from "./ProductInfo.module.css";
import styleDark from "./ProductInfoDark.module.css";

const style = isDarkMode ? styleDark : styleNormal;

const cx = classNames.bind(style);

const ProductInfo = ({ modelInfo: { description = "", model = "" } }) => {
  return (
    <header className={cx(isMobile ? style.headerMobile : style.header)}>
      <h1
        className={cx(
          style.resetDefaultHeader,
          isMobile ? style.descriptionMobile : style.description
        )}
      >
        {description}
      </h1>
      <h2
        className={cx(
          style.resetDefaultHeader,
          isMobile ? style.modelMobile : style.model
        )}
      >
        {model}
      </h2>
    </header>
  );
};

ProductInfo.propTypes = {
  modelInfo: PropTypes.shape({
    description: PropTypes.string,
    model: PropTypes.string,
  }).isRequired,
};

export default ProductInfo;
