import camelcaseKeys from "camelcase-keys";

const getData = async (url = "", headers = {}) => {
  const response = await fetch(url, {
    headers,
  });
  if (!response.ok) throw Error(response.statusText);

  const data = await response.json();

  // change the response object keys format to camelCase
  return camelcaseKeys(data, { deep: true });
};

export default getData;
