import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  GoogleMap,
  LoadScript,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import { isIE11, isDarkMode } from "utils";
import { googleMapsApiKey, defaultLocation } from "config";
import MAP_ICONS from "assets/icons";
import mapOptions from "./Map/mapOptions";
import markerTemplate from "./Map/markerTemplate";
import UserLocationMarker from "./Map/UserLocationMarker";

import style from "./Map.module.css";
import overlayStyle from "./Overlay.module.css";

const Map = ({
  filters,
  handleRetailerDetailsClose,
  handleRetailerPick,
  handleSearchThisArea,
  isMobile = true,
  isProductUnavailableInGivenRange,
  localStores,
  location = defaultLocation,
  modelInfo: { model, modelCode },
  overlayActive,
  pickedStoreId,
  pickedStoreLocation,
  setMarkerClicked,
  setPickedStoreId,
  setPickedStoreLocation,
  setShouldAutoLocate,
  setZoom,
  usingAutoLocation,
  zoom = 10,
}) => {
  const [changedPerspective, setChangedPerspective] = useState(false);

  const containerStyle = {
    position: isMobile ? "absolute" : "fixed",
    width: isMobile ? "100%" : "51.5vw",
    // eslint-disable-next-line no-nested-ternary
    height: isMobile
      ? // eslint-disable-next-line no-nested-ternary
        isProductUnavailableInGivenRange
        ? "calc(100vh - 363px)"
        : !usingAutoLocation
        ? "calc(100vh - 275px)"
        : "calc(100vh - 231px)"
      : "73.5vh",
    borderRadius: "0 0 15px 0",
    // eslint-disable-next-line no-nested-ternary
    top: isMobile
      ? // eslint-disable-next-line no-nested-ternary
        isProductUnavailableInGivenRange
        ? "363px"
        : !usingAutoLocation
        ? "275px"
        : "231px"
      : "0px",
  };

  const getImgURL = (number) =>
    MAP_ICONS.CLUSTERS[`${isDarkMode ? "b" : "m"}${number}`];

  const options = {
    minimumClusterSize: 4,
    styles: [
      {
        url: getImgURL(1),
        width: 53,
        height: 53,
        textColor: isDarkMode ? "#000" : "#fff",
      },
      {
        url: getImgURL(2),
        width: 71,
        height: 71,
        textColor: isDarkMode ? "#000" : "#fff",
      },
      {
        url: getImgURL(3),
        width: 80,
        height: 80,
        textColor: isDarkMode ? "#000" : "#fff",
      },
      {
        url: getImgURL(4),
        width: 89,
        height: 89,
        textColor: isDarkMode ? "#000" : "#fff",
      },
      {
        url: getImgURL(5),
        width: 98,
        height: 98,
        textColor: isDarkMode ? "#000" : "#fff",
      },
    ],
  };

  const refMarker = useRef();

  const renderMarkers = (clusterer) => {
    const filtersActive = filters && filters.length > 0;
    localStores.map((store, index) => {
      return Object.assign(store, { pinIndex: index });
    });
    const markerStores = filtersActive
      ? localStores.filter(
          (localStore) => filters.indexOf(localStore.retailerId) >= 0
        )
      : localStores;
    return markerStores.map((store) => {
      const isBrandStore = store.retailerId === "sklepsamsungpl_pl";
      const isClicked =
        `${store.latitude}_${store.longitude}_${store.retailerName}` ===
        pickedStoreId;

      let svg = markerTemplate.replace("{{index}}", store.pinIndex + 1);
      if (isClicked) {
        svg = svg.replace("{{width}}", "34px");
        svg = svg.replace("{{height}}", "46px");
        setZoom(15);
      } else {
        svg = svg.replace("{{width}}", "24px");
        svg = svg.replace("{{height}}", "32px");
      }
      if (isDarkMode) {
        // tu sa darki
        if (isBrandStore) {
          // tu sa darki niebieskie
          svg = svg.replace("{{color}}", "#ffffff");
          svg = svg.replace("{{color2}}", "#14289B");
          svg = svg.replace("{{textColor}}", "#ffffff");
        } else {
          // tu sa darki czarne
          svg = svg.replace("{{color}}", "#ffffff");
          svg = svg.replace("{{color2}}", "#000000");
          svg = svg.replace("{{textColor}}", "#ffffff");
        }
      } else if (isBrandStore) {
        svg = svg.replace("{{color}}", "#14289B");
        svg = svg.replace("{{color2}}", "#ffffff");
        svg = svg.replace("{{textColor}}", "#14289B");
      } else {
        svg = svg.replace("{{color}}", "#000000");
        svg = svg.replace("{{color2}}", "#ffffff");
        svg = svg.replace("{{textColor}}", "#000000");
      }
      let url = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
      if (isIE11) {
        if (isBrandStore) {
          if (isDarkMode) {
            url = MAP_ICONS.PINS.whiteBlue;
          } else {
            url = MAP_ICONS.PINS.blue;
          }
        } else if (isDarkMode) {
          url = MAP_ICONS.PINS.white;
        } else {
          url = MAP_ICONS.PINS.black;
        }
      }
      const handleRetailerClick = (retailer) => {
        setPickedStoreId(
          `${retailer.latitude}_${retailer.longitude}_${retailer.retailerName}`
        );
        refMarker.current.setAttribute(
          "data-omni",
          `buy in store: ${retailer.retailerName};|${modelCode}|${model}`
        );
        refMarker.current.click();
        setPickedStoreLocation({
          lat: retailer.latitude,
          lng: retailer.longitude,
        });
        setMarkerClicked(true);
        if (isMobile) {
          handleRetailerPick(retailer);
        }
      };

      return (
        <Marker
          clusterer={clusterer}
          key={`${store.latitude} ${store.longitude}`}
          onClick={() => handleRetailerClick(store)}
          options={{
            icon: {
              url,
            },
            optimized: false,
          }}
          position={{ lat: store.latitude, lng: store.longitude }}
        />
      );
    });
  };

  const mapRef = useRef();

  useEffect(() => {
    setShouldAutoLocate(!changedPerspective);
  }, [changedPerspective, setShouldAutoLocate]);

  function handleChangePerspective(eventType) {
    if (
      !mapRef.current ||
      (eventType === "zoom" && mapRef.current.state.map.zoom === zoom)
    )
      return;
    setChangedPerspective(true);
  }

  function handleClickSearchThisArea() {
    const newMapState = mapRef.current.state.map;
    const newMapCenter = {
      lat: newMapState.center.lat(),
      lng: newMapState.center.lng(),
    };
    const newMapZoom = newMapState.zoom;

    handleSearchThisArea(newMapCenter, newMapZoom);
    setChangedPerspective(false);
  }

  const renderSearchThisAreaButton = () => {
    const searchWrapClassName = `swcn ${style.searchWrapper} ${
      !changedPerspective ? style.hidden : ""
    }`;
    return (
      <div className={`${searchWrapClassName}`}>
        <button
          className={style.searchThisAreaBtn}
          onClick={handleClickSearchThisArea}
          type="button"
        >
          Szukaj na tym obszarze
        </button>
      </div>
    );
  };

  const positionSearchButton = () => {
    const posInterval = setInterval(() => {
      const map = document.getElementsByClassName("gm-style")[0];

      if (!map) return;

      const mapWidth = map.clientWidth;
      const mapX = map.getBoundingClientRect().left;
      const mapY = map.getBoundingClientRect().top;
      const searchBtnWrapper = document.getElementsByClassName("swcn")[0];

      if (!searchBtnWrapper) return;

      const searchBtnWrapperWidth = 200;
      const wrapLeftOffset = mapWidth / 2 - searchBtnWrapperWidth / 2;

      searchBtnWrapper.style.left = `${mapX + wrapLeftOffset}px`;
      searchBtnWrapper.style.top = `${mapY + 31}px`;

      clearInterval(posInterval);
    }, 100);
  };

  useEffect(() => {
    positionSearchButton();
  }, []);

  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey}>
      <div className={overlayActive ? overlayStyle.overlaidElement : ""}>
        {renderSearchThisAreaButton()}
        <div
          style={
            isMobile
              ? {}
              : {
                  position: "fixed",
                }
          }
        >
          <GoogleMap
            center={pickedStoreLocation || location}
            mapContainerStyle={containerStyle}
            onClick={isMobile && handleRetailerDetailsClose}
            onDragEnd={() => handleChangePerspective("drag")}
            onZoomChanged={() => handleChangePerspective("zoom")}
            options={mapOptions}
            ref={mapRef}
            zoom={zoom}
          >
            <MarkerClusterer options={options}>
              {(clusterer) => renderMarkers(clusterer)}
            </MarkerClusterer>
            {/* button for data-omni, clicked via handleRetailerClick method */}
            <button
              data-omni-type="microsite_buyOnline"
              ref={refMarker}
              style={{ display: "none" }}
              type="button"
            />
            <UserLocationMarker location={location} />
          </GoogleMap>
          {!isMobile && overlayActive && (
            <div className={`${overlayStyle.overlay} ${overlayStyle.map}`} />
          )}
        </div>
      </div>
    </LoadScript>
  );
};

Map.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string),
  handleRetailerDetailsClose: PropTypes.func,
  handleRetailerPick: PropTypes.func.isRequired,
  handleSearchThisArea: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isProductUnavailableInGivenRange: PropTypes.bool.isRequired,
  localStores: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
  modelInfo: PropTypes.shape({
    model: PropTypes.string,
    modelCode: PropTypes.string,
  }).isRequired,
  overlayActive: PropTypes.bool,
  pickedStoreId: PropTypes.string,
  pickedStoreLocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  setMarkerClicked: PropTypes.func.isRequired,
  setPickedStoreId: PropTypes.func.isRequired,
  setPickedStoreLocation: PropTypes.func.isRequired,
  setShouldAutoLocate: PropTypes.func,
  setZoom: PropTypes.func.isRequired,
  usingAutoLocation: PropTypes.bool,
  zoom: PropTypes.number,
};

export default Map;
