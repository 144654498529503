import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as FiltersOpenIcon } from "assets/icons/arrow.svg";
import { ReactComponent as FiltersActiveIcon } from "assets/icons/filters_selected.svg";
import { ReactComponent as FiltersInactiveIcon } from "assets/icons/filters.svg";
import { isIE11 } from "utils";
import styleNormal from "./FiltersIcon.module.css";
import styleIE11 from "./FiltersIconIE11.module.css";

const style = isIE11 ? styleIE11 : styleNormal;

const FiltersIcon = ({
  currentFiltersCount,
  filtersOpen,
  handleClick,
  modelInfo: { model, modelCode },
}) => {
  const CurrentIcon =
    (filtersOpen && FiltersOpenIcon) ||
    (currentFiltersCount && FiltersActiveIcon) ||
    FiltersInactiveIcon;

  return (
    <div className={style.filtersIconWrap}>
      <CurrentIcon
        onClick={handleClick}
        data-omni-type="microsite_buyStore"
        data-omni={`filter open|;${modelCode}|${model}`}
      />
    </div>
  );
};

FiltersIcon.propTypes = {
  currentFiltersCount: PropTypes.number,
  filtersOpen: PropTypes.bool,
  handleClick: PropTypes.func,
  modelInfo: PropTypes.shape({
    model: PropTypes.string,
    modelCode: PropTypes.string,
  }).isRequired,
};

export default FiltersIcon;
