import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import LocationPinWithIndex from "./LocationPinWithIndex";
import style from "./StoreHeaderSection.module.css";

const cx = classNames.bind(style);

const StoreHeaderSection = ({
  isBrandStore,
  isCardDetail,
  index,
  retailerDisplayName,
  distanceFromUserLocation,
}) => (
  <div className={cx(style.rowHeader, isCardDetail && style.cardDetails)}>
    <LocationPinWithIndex isBrandStore={isBrandStore} index={index} />
    <span className={cx(style.retailerDisplayName, isBrandStore && style.blue)}>
      {retailerDisplayName}
    </span>
    <span className={style.distance}>{`${distanceFromUserLocation} km`}</span>
  </div>
);

StoreHeaderSection.propTypes = {
  distanceFromUserLocation: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isBrandStore: PropTypes.bool.isRequired,
  isCardDetail: PropTypes.bool,
  retailerDisplayName: PropTypes.string.isRequired,
};

export default StoreHeaderSection;
