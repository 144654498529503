// FIXME change to SVG file from assets
const markerTemplate = [
  '<?xml version="1.0" encoding="UTF-8"?>',
  '<svg width="{{width}}" height="{{height}}" viewBox="0 0 16 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">',
  "<title>Group 2 Copy 2</title>",
  '<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">',
  '<g id="List/Desktop_Local" transform="translate(-16.000000, -18.000000)">',
  '<g id="Group-2-Copy-2" transform="translate(16.000000, 18.000000)">',
  '<g id="Group-6">',
  '<path d="M7.878259,2.08721929e-14 C4.243982,2.08721929e-14 5.06261699e-14,2.069892 5.06261699e-14,7.904402 C5.06261699e-14,13.559216 6.862917,20.16146 7.155045,20.439562 C7.355509,20.630654 7.616816,20.7262 7.878259,20.7262 C8.139702,20.7262 8.401026,20.630654 8.60149,20.439562 C8.893618,20.16146 15.756535,13.559216 15.756535,7.904402 C15.756535,2.069892 11.512553,2.08721929e-14 7.878259,2.08721929e-14 Z" id="location" fill="{{color}}"></path>',
  '<g id="Group-5" transform="translate(2.302857, 2.324444)" fill="{{color2}}">',
  '<circle id="Oval" cx="5.69714286" cy="5.67555556" r="5"></circle>',
  "</g>",
  "</g>",
  "</g>",
  "</g>",
  "</g>",
  '<text fill="{{textColor}}" text-anchor="middle" font-family="Arial" transform="matrix(1 0 0 1 7.493 10.8244)" font-size="7px" class="st3 st4">{{index}}</text>',
  "</svg>",
].join("\n");

export default markerTemplate;
