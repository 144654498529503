import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  calcMapSearchRange,
  isIE11,
  geocode,
  getCurrentLocation,
  getZoomByRange,
  parsePhoneNumber,
} from "utils";
import { isMobile } from "config";
import Controls from "./LocalStores/Controls/Controls.jsx";
import DataFetcher from "./DataFetcher";
import DetailCard from "./DetailCard/DetailCard";
import Map from "./LocalStores/Map";
import ProductUnavailable from "./ProductUnavailable";
import ProductUnavailableInGivenRange from "./LocalStores/ProductUnavailableInGivenRange";
import RetailerList from "./LocalStores/RetailerList.jsx";
import SwitchBtn from "./LocalStores/Switch/Switch";

import styleNormal from "./LocalStores.module.css";
import styleIE11 from "./LocalStoresIE11.module.css";

const style = isIE11 ? styleIE11 : styleNormal;

let locationRangeLock = false;
let shouldAutoLocate = true;

const LocalStores = ({
  distanceFromNearestStore,
  localStores = [],
  modelInfo,
  switchTab,
}) => {
  const [fetchedStores, setFetchedStores] = useState({
    distanceFromNearestStore,
    localStores,
    isLoadingComplete: true,
  });
  const [currentAddress, setCurrentAddress] = useState();
  const [currentFilters, setCurrentFilters] = useState([]);
  const [isMarkerClicked, setMarkerClicked] = useState(false);
  const [location, setLocation] = useState();
  const [overlayActive, setOverlayActive] = useState(false);
  const [pickedStore, setPickedStore] = useState();
  const [pickedStoreId, setPickedStoreId] = useState();
  const [pickedStoreLocation, setPickedStoreLocation] = useState();
  const [range, setRange] = useState();
  const [shouldFetch, triggerFetch] = useState(false);
  const [shouldRenderMap, setRenderMap] = useState(true);
  const [usingAutoLocation, setUsingAutoLocation] = useState(false);
  const [zoom, setZoom] = useState(10);

  const { isLoadingComplete } = fetchedStores;

  useEffect(() => {
    if (shouldFetch)
      setFetchedStores({ ...fetchedStores, isLoadingComplete: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  const handleRetailerDetailsClose = () => {
    setPickedStore();
    setPickedStoreId();
    setPickedStoreLocation();
    setZoom(10);
  };

  function handleSetLocation(newLocation, mode) {
    handleRetailerDetailsClose();
    switch (mode) {
      case "auto":
        if (shouldAutoLocate) {
          setLocation(newLocation);
          setUsingAutoLocation(true);
        }
        break;
      case "user":
        setLocation(newLocation);
        setUsingAutoLocation(true);
        break;
      case "searchThisArea":
        setLocation(newLocation);
        break;
      case "addressSearch":
        setLocation(newLocation);
        break;
      default:
        if (process.env.NODE_ENV !== "production")
          console.error("unknown set location mode", mode);
    }
  }

  const handleSetAutoLocation = (loc) => {
    handleSetLocation(loc, "auto");
  };

  const triggerAutoLocation = () => {
    getCurrentLocation(handleSetAutoLocation);
  };

  const handleSetUserLocation = (loc) => {
    handleSetLocation(loc, "user");
  };

  const triggerUserLocation = () => {
    getCurrentLocation(handleSetUserLocation);
  };

  function setShouldAutoLocate(newRule) {
    shouldAutoLocate = newRule;
  }

  const handleSearchThisArea = (center, currentZoom) => {
    setUsingAutoLocation(false);
    locationRangeLock = true;
    const mapRange = calcMapSearchRange(currentZoom, center.lat);
    handleSetLocation(center, "searchThisArea");
    setRange(mapRange);
    triggerFetch(true);
    locationRangeLock = false;
  };

  useEffect(() => {
    triggerAutoLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (locationRangeLock) return;
    if (location) {
      geocode
        .getAddressForCoords(location.lat, location.lng)
        .then((response) =>
          setCurrentAddress(response.results[0].formatted_address)
        );
      triggerFetch(true);
    }
  }, [location]);

  useEffect(() => {
    if (locationRangeLock) return;
    if (range) triggerFetch(true);
  }, [range]);

  useEffect(() => {
    if (isLoadingComplete) {
      triggerFetch(false);
      setRange(undefined);
    }
  }, [isLoadingComplete]);

  const searchNearby = () => {
    // max searching range for channelAdvisor is 1600 km
    if (distanceFromNearestStore <= 1595) {
      const newRange = distanceFromNearestStore + 5; // set new search range to 5 km more than distance to nearest store
      setRange(newRange);
      setZoom(getZoomByRange(newRange));
    } else {
      const { wholeCountryLocalStores } = fetchedStores;
      const newRange =
        wholeCountryLocalStores.length &&
        wholeCountryLocalStores[0].distanceFromUserLocation;
      setFetchedStores({
        localStores: wholeCountryLocalStores,
        isLoadingComplete: true,
      });
      setZoom(getZoomByRange(newRange));
    }
  };

  const handleRetailerPick = (retailer) => {
    setRenderMap(!shouldRenderMap);
    setPickedStore(retailer);
  };

  const handleRetailerPickMobile = (retailer) => {
    setPickedStore(retailer);
  };

  const renderRetailers = () => (
    <RetailerList
      filters={currentFilters}
      handleRetailerPick={handleRetailerPick}
      isMarkerClicked={isMarkerClicked}
      localStores={fetchedStores.localStores}
      modelInfo={modelInfo}
      overlayActive={overlayActive}
      pickedStoreId={pickedStoreId}
      setMarkerClicked={setMarkerClicked}
      setPickedStoreId={setPickedStoreId}
      setPickedStoreLocation={setPickedStoreLocation}
      usingAutoLocation={usingAutoLocation}
    />
  );

  const renderControls = () => (
    <Controls
      currentAddress={currentAddress}
      currentFiltersCount={currentFilters.length}
      distanceFromNearestStore={fetchedStores.distanceFromNearestStore}
      handleRetailerDetailsClose={handleRetailerDetailsClose}
      handleSetFilters={setCurrentFilters}
      handleSetLocation={handleSetLocation}
      modelInfo={modelInfo}
      setCurrentAddress={setCurrentAddress}
      setOverlayActive={setOverlayActive}
      setUsingAutoLocation={setUsingAutoLocation}
      shouldRenderMap={shouldRenderMap}
      stores={isLoadingComplete ? fetchedStores.localStores : []}
      triggerUserLocation={triggerUserLocation}
      usingAutoLocation={usingAutoLocation}
    />
  );

  if (isMobile)
    return (
      <>
        {shouldFetch && (
          <DataFetcher
            location={location}
            range={range}
            setFetchedData={setFetchedStores}
          />
        )}
        {fetchedStores.localStores.length ||
        fetchedStores.distanceFromNearestStore ? (
          <>
            {renderControls()}
            {fetchedStores.distanceFromNearestStore && (
              <ProductUnavailableInGivenRange
                /* FIXME trackers
                data-omni-type="microsite_buyOnline"
                data-omni={`buy online|;${modelInfo.modelCode}|${modelInfo.model}`}
                */
                searchNearby={searchNearby}
                switchTab={switchTab}
              />
            )}
            {shouldRenderMap ? (
              <Map
                distanceFromNearestStore={
                  fetchedStores.distanceFromNearestStore
                }
                filters={currentFilters}
                handleRetailerDetailsClose={handleRetailerDetailsClose}
                handleRetailerPick={handleRetailerPickMobile}
                handleSearchThisArea={handleSearchThisArea}
                isMobile
                isProductUnavailableInGivenRange={
                  fetchedStores.localStores.length === 0
                }
                localStores={fetchedStores.localStores}
                location={location}
                modelInfo={modelInfo}
                overlayActive={overlayActive}
                pickedStoreId={pickedStoreId}
                pickedStoreLocation={pickedStoreLocation}
                setMarkerClicked={setMarkerClicked}
                setPickedStoreId={setPickedStoreId}
                setPickedStoreLocation={setPickedStoreLocation}
                setShouldAutoLocate={setShouldAutoLocate}
                setZoom={setZoom}
                usingAutoLocation={usingAutoLocation}
                zoom={zoom}
              />
            ) : (
              renderRetailers()
            )}
          </>
        ) : (
          <ProductUnavailable
            /* FIXME trackers
            data-omni-type="microsite_buyOnline"
            data-omni={`buy online|;${modelInfo.modelCode}|${modelInfo.model}`}
            */
            storeType="local"
            switchTab={switchTab}
          />
        )}
        <SwitchBtn
          disabled={!!fetchedStores.distanceFromNearestStore || overlayActive}
          isStorePicked={!!pickedStore && shouldRenderMap}
          handleClick={() => setRenderMap(!shouldRenderMap)}
          title={shouldRenderMap ? "LISTA" : "MAPA"}
        />
        {shouldRenderMap && pickedStore && (
          <DetailCard
            index={
              currentFilters.length
                ? fetchedStores.localStores
                    .filter(
                      (localStore) =>
                        currentFilters.indexOf(localStore.retailerId) >= 0
                    )
                    .indexOf(pickedStore)
                : fetchedStores.localStores.indexOf(pickedStore)
            }
            city={pickedStore.city}
            disabled={fetchedStores.distanceFromNearestStore || overlayActive}
            distanceFromUserLocation={pickedStore.distanceFromUserLocation}
            hours={pickedStore.hours}
            isBrandStore={false}
            modelInfo={modelInfo}
            openToday={pickedStore.openToday}
            phone={parsePhoneNumber(pickedStore.phone)}
            retailerName={pickedStore.retailerName}
            retailerDisplayName={pickedStore.retailerDisplayName}
            retailerId={pickedStore.retailerId}
            streetLine1={pickedStore.streetLine1}
            streetLine2={pickedStore.streetLine2}
          />
        )}
      </>
    );

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      {shouldFetch && (
        <DataFetcher
          location={location}
          range={range}
          setFetchedData={setFetchedStores}
        />
      )}
      {fetchedStores.localStores.length ||
      fetchedStores.distanceFromNearestStore ? (
        <div className={style.listContainer}>
          <div className={style.itemLeftPane}>
            {renderControls()}
            {!fetchedStores.distanceFromNearestStore && renderRetailers()}
            {fetchedStores.distanceFromNearestStore && (
              <ProductUnavailableInGivenRange
                /* FIXME trackers
                data-omni-type="microsite_buyOnline"
                data-omni={`buy online|;${modelInfo.modelCode}|${modelInfo.model}`}
                */
                searchNearby={searchNearby}
                switchTab={switchTab}
              />
            )}
          </div>
          <div className={style.item}>
            <Map
              filters={currentFilters}
              handleRetailerPick={handleRetailerPick}
              handleSearchThisArea={handleSearchThisArea}
              isMobile={false}
              isProductUnavailableInGivenRange={
                fetchedStores.localStores.length === 0
              }
              localStores={fetchedStores.localStores}
              location={location}
              modelInfo={modelInfo}
              overlayActive={overlayActive}
              pickedStoreId={pickedStoreId}
              pickedStoreLocation={pickedStoreLocation}
              setMarkerClicked={setMarkerClicked}
              setPickedStoreId={setPickedStoreId}
              setPickedStoreLocation={setPickedStoreLocation}
              setShouldAutoLocate={setShouldAutoLocate}
              setZoom={setZoom}
              zoom={zoom}
            />
          </div>
        </div>
      ) : (
        <ProductUnavailable
          /* FIXME trackers
          data-omni-type="microsite_buyOnline"
          data-omni={`buy online|;${modelInfo.modelCode}|${modelInfo.model}`}
          */
          storeType="local"
          switchTab={switchTab}
        />
      )}
    </div>
  );
};

LocalStores.propTypes = {
  distanceFromNearestStore: PropTypes.number,
  localStores: PropTypes.arrayOf(PropTypes.object).isRequired,
  modelInfo: PropTypes.shape({
    model: PropTypes.string,
    modelCode: PropTypes.string,
  }).isRequired,
  switchTab: PropTypes.func.isRequired,
};

export default LocalStores;
