function parsePhoneNumber(phone) {
  if (phone === undefined || phone.length === 0) return "";
  let number = phone.replace(/ |\+|-/g, "");
  if (
    number.length > 10 &&
    number.charAt(0) === "4" &&
    number.charAt(1) === "8"
  ) {
    number = number.slice(2, number.length);
  }
  const direct = [
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "22",
    "23",
    "24",
    "25",
    "29",
    "32",
    "33",
    "34",
    "41",
    "42",
    "43",
    "44",
    "46",
    "48",
    "52",
    "54",
    "55",
    "56",
    "58",
    "59",
    "61",
    "62",
    "63",
    "65",
    "67",
    "68",
    "71",
    "74",
    "75",
    "76",
    "77",
    "81",
    "82",
    "83",
    "84",
    "85",
    "86",
    "87",
    "89",
    "91",
    "94",
    "95",
  ];
  if (direct.includes(phone.substring(0, 2))) {
    number = `${number.substring(0, 2)} ${number.substring(
      2,
      5
    )} ${number.substring(5, 7)} ${number.substring(7, 9)}`;
  } else {
    number = `${number.substring(0, 3)} ${number.substring(
      3,
      6
    )} ${number.substring(6, 9)}`;
  }
  return number;
}

export default parsePhoneNumber;
