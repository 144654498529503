import Geocode from "react-geocode";
import { googleMapsApiKey } from "config";

Geocode.setApiKey(googleMapsApiKey);
Geocode.setLanguage("pl");
Geocode.setRegion("pl");

const getCoordsForAddress = Geocode.fromAddress;
const getAddressForCoords = Geocode.fromLatLng;

const geocode = {
  getCoordsForAddress,
  getAddressForCoords,
};

export default geocode;
