import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LocationIcon } from "assets/icons/location_outline.svg";
import { ReactComponent as OnlineIcon } from "assets/icons/online_outline.svg";

import style from "./ProductUnavailable.module.css";

const NOTICE_TEXTS = {
  online:
    "Przepraszamy, na chwilę obecną nie mamy informacji o dostępności poszukiwanego przez Ciebie produktu u naszych partnerów online.",
  local:
    "Przepraszamy, na chwilę obecną nie mamy informacji o dostępności poszukiwanego przez Ciebie produktu u naszych partnerów online.",
};

const BUTTON_TEXTS = {
  online: "ZNAJDŹ SKLEP STACJONARNY",
  local: "KUP ONLINE",
};

const getIcon = (storeType) =>
  ({
    online: <LocationIcon />,
    local: <OnlineIcon />,
  }[storeType]);

const ProductUnavailable = ({
  storeType,
  switchTab,
  /* FIXME trackers
  ...dataOmniAttributes
  */
}) => (
  <div className={style.unavailable}>
    <span>{NOTICE_TEXTS[storeType]}</span>
    <button
      className={style.findOtherStoreButton}
      type="button"
      onClick={switchTab}
      /* FIXME trackers
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...dataOmniAttributes}
      */
    >
      <div className={style.findOtherStore}>
        {getIcon(storeType)}
        <span>{BUTTON_TEXTS[storeType]}</span>
      </div>
    </button>
  </div>
);

ProductUnavailable.propTypes = {
  storeType: PropTypes.oneOf(["online", "local"]).isRequired,
  switchTab: PropTypes.func.isRequired,
};

export default ProductUnavailable;
