import { isMobile } from "config";

const calcMapSearchRange = (zoom, lat) => {
  const metersPerPx =
    (156543.03392 * Math.cos((lat * Math.PI) / 180)) / 2 ** zoom;
  const mapWidth =
    Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    ) * (isMobile ? 1 : 0.5); // map component width as set in Map.jsx

  return Math.ceil((metersPerPx * mapWidth) / 2000);
};

const getZoomByRange = (range, lat = 52) => {
  let targetZoom = 22;
  const minZoom = 0;
  while (calcMapSearchRange(targetZoom, lat) < range && targetZoom > minZoom) {
    targetZoom -= 1;
  }

  return targetZoom;
};

export { calcMapSearchRange, getZoomByRange };
