import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ReactComponent as DirectionsActiveIcon } from "assets/icons/round_directions_hover.svg";
import { ReactComponent as PhoneActiveIcon } from "assets/icons/round_phone_hover.svg";

import { mapsSelector } from "utils";
import StoreHeaderSection from "../LocalStores/RetailerList/StoreHeaderSection";
import StoreAddressSection from "../LocalStores/RetailerList/StoreAddressSection";
import StoreFooterSection from "../LocalStores/RetailerList/StoreFooterSection";

import style from "./DetailCard.module.css";

const cx = classNames.bind(style);

const DetailCard = ({
  city,
  disabled,
  distanceFromUserLocation,
  hours,
  index,
  modelInfo: { model, modelCode },
  openToday,
  phone,
  retailerDisplayName,
  retailerId,
  retailerName,
  streetLine1,
  streetLine2,
}) => {
  const isBrandStore = retailerId === "sklepsamsungpl_pl";

  return (
    <div className={cx(style.detailCardContainer, disabled && style.disabled)}>
      <div className={style.detailCardLeft}>
        <StoreHeaderSection
          distanceFromUserLocation={distanceFromUserLocation}
          index={index}
          isBrandStore={isBrandStore}
          isCardDetail
          retailerDisplayName={retailerDisplayName}
        />
        <StoreAddressSection
          streetLine1={streetLine1}
          streetLine2={streetLine2}
          city={city}
        />
        <StoreFooterSection hours={hours} openToday={openToday} />
      </div>
      <div className={style.detailCardRight}>
        {/* FIXME should use DirectionsIcon */}
        <button
          className={style.navigateToButton}
          data-omni-type="microsite_buyOnline"
          data-omni={`buy in store – google map:${retailerName}|;${modelCode}|${model}`}
          onClick={() => mapsSelector(`${city} ${streetLine1} ${streetLine2}`)}
          type="button"
        >
          <DirectionsActiveIcon />
        </button>
        {phone && (
          <a
            className={style.phoneButton}
            data-omni-type="microsite_buyOnline"
            data-omni={`buy in store – call to store:${retailerName}|;${modelCode}|${model}`}
            href={`tel:${phone}`}
          >
            <PhoneActiveIcon />
          </a>
        )}
      </div>
    </div>
  );
};
DetailCard.propTypes = {
  index: PropTypes.number,
  city: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  distanceFromUserLocation: PropTypes.number,
  hours: PropTypes.string,
  modelInfo: PropTypes.shape({
    model: PropTypes.string,
    modelCode: PropTypes.string,
  }).isRequired,
  openToday: PropTypes.string,
  phone: PropTypes.string,
  retailerName: PropTypes.string,
  retailerDisplayName: PropTypes.string,
  retailerId: PropTypes.string.isRequired,
  streetLine1: PropTypes.string,
  streetLine2: PropTypes.string,
};

export default DetailCard;
