import calcMapHeight from "./calcMapHeight";
import geocode from "./geocode";
import getCurrentLocation from "./getCurrentLocation";
import getData from "./getData";
import getThemeStyle from "./getThemeStyle";
import isIE11 from "./isIE11";
import isSafari from "./isSafari";
import mapsSelector from "./mapsSelector";
import parsePhoneNumber from "./parsePhoneNumber";
import postData from "./postData";
import processData from "./processData";
import { calcMapSearchRange, getZoomByRange } from "./calcMapSearchRange";

const isDarkMode = getThemeStyle === "dark";

export {
  calcMapHeight,
  calcMapSearchRange,
  isDarkMode,
  geocode,
  getCurrentLocation,
  getData,
  getZoomByRange,
  isIE11,
  isSafari,
  mapsSelector,
  parsePhoneNumber,
  postData,
  processData,
};
