import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { geocode } from "utils";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as UseLocationIcon } from "assets/icons/use_location.svg";
import { isMobile } from "config";
import style from "./AddressInput.module.css";

const cx = classNames.bind(style);

const AddressInput = ({
  currentAddress,
  handleSetLocation,
  modelInfo: { model, modelCode },
  usingAutoLocation,
  setUsingAutoLocation,
}) => {
  const [address, setAddress] = useState(currentAddress || "");

  useEffect(() => {
    if (currentAddress) {
      setAddress(currentAddress);
    }
  }, [currentAddress]);

  const setLocationForGivenAddress = (event) => {
    event.preventDefault();
    if (address) {
      geocode
        .getCoordsForAddress(address)
        .then((response) =>
          handleSetLocation(
            response.results[0].geometry.location,
            "addressSearch"
          )
        );
    }
  };

  function handleChangeInput(newInputVal) {
    setAddress(newInputVal);
    setUsingAutoLocation(false);
  }

  return (
    <form
      className={cx(isMobile ? style.formGroupMobile : style.formGroupDesktop)}
      data-omni-type="microsite_buyStore"
      data-omni={`search|;${modelCode}|${model}`}
      onSubmit={setLocationForGivenAddress}
    >
      <div
        className={`${usingAutoLocation ? "" : "hidden"} ${
          style.formAutoLocationWrap
        }`}
      >
        {usingAutoLocation && <UseLocationIcon />}
      </div>
      <input
        className={cx(
          isMobile ? style.formFieldMobile : style.formFieldDesktop
        )}
        id="location"
        onChange={(event) => handleChangeInput(event.target.value)}
        placeholder="Wpisz adres..."
        type="text"
        value={address}
      />
      <button className={style.formButton} type="submit">
        <SearchIcon />
      </button>
    </form>
  );
};

AddressInput.propTypes = {
  currentAddress: PropTypes.string,
  handleSetLocation: PropTypes.func.isRequired,
  modelInfo: PropTypes.shape({
    model: PropTypes.string,
    modelCode: PropTypes.string,
  }).isRequired,
  usingAutoLocation: PropTypes.bool,
  setUsingAutoLocation: PropTypes.func,
};

export default AddressInput;
