import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isMobile } from "config";
import Filters from "./Filters";
import FiltersIcon from "./FiltersIcon/FiltersIcon";
import FindInMyLocation from "./FindInMyLocation";
import AddressInput from "./AddressInput";
import style from "./Controls.module.css";

const cx = classNames.bind(style);

const Controls = ({
  currentAddress,
  currentFiltersCount,
  distanceFromNearestStore,
  handleRetailerDetailsClose,
  handleSetFilters,
  handleSetLocation,
  modelInfo,
  setOverlayActive,
  setUsingAutoLocation,
  shouldRenderMap,
  stores,
  triggerUserLocation,
  usingAutoLocation,
}) => {
  const [filtersOpen, setFiltersOpen] = useState(false);

  useEffect(() => {
    setOverlayActive(filtersOpen);
  }, [filtersOpen, setOverlayActive]);

  return (
    <div
      className={cx(
        shouldRenderMap && isMobile && style.controlsMobileVisible__map,
        isMobile && style.controlsMobileVisible
      )}
    >
      <div
        className={cx(
          isMobile ? style.controlsMobile : style.controlsDesktop,
          !isMobile &&
            usingAutoLocation &&
            style.controlsDesktopWithoutLocationButton
        )}
      >
        <div className={style.formRowWrap}>
          <AddressInput
            currentAddress={currentAddress}
            handleSetLocation={handleSetLocation}
            modelInfo={modelInfo}
            setUsingAutoLocation={setUsingAutoLocation}
            usingAutoLocation={usingAutoLocation}
          />
          <FiltersIcon
            currentFiltersCount={currentFiltersCount}
            handleClick={() => {
              handleRetailerDetailsClose();
              setFiltersOpen(!filtersOpen);
            }}
            filtersOpen={filtersOpen}
            modelInfo={modelInfo}
          />
        </div>
      </div>
      <Filters
        filtersOpen={filtersOpen}
        handleSetFilters={handleSetFilters}
        modelInfo={modelInfo}
        setFiltersOpenState={setFiltersOpen}
        stores={stores}
      />
      {!usingAutoLocation && !distanceFromNearestStore && (
        <FindInMyLocation
          modelInfo={modelInfo}
          triggerUserLocation={triggerUserLocation}
        />
      )}
    </div>
  );
};

Controls.propTypes = {
  currentAddress: PropTypes.string,
  currentFiltersCount: PropTypes.number,
  distanceFromNearestStore: PropTypes.number,
  handleRetailerDetailsClose: PropTypes.func.isRequired,
  handleSetFilters: PropTypes.func.isRequired,
  handleSetLocation: PropTypes.func.isRequired,
  modelInfo: PropTypes.shape({
    model: PropTypes.string,
    modelCode: PropTypes.string,
  }).isRequired,
  setOverlayActive: PropTypes.func.isRequired,
  setUsingAutoLocation: PropTypes.func,
  shouldRenderMap: PropTypes.bool,
  stores: PropTypes.arrayOf(PropTypes.object),
  triggerUserLocation: PropTypes.func,
  usingAutoLocation: PropTypes.bool,
};

export default Controls;
