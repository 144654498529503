function mapsSelector(add) {
  const address = encodeURI(add);
  if (
    navigator.platform.indexOf("iPhone") !== -1 ||
    navigator.platform.indexOf("iPad") !== -1 ||
    navigator.platform.indexOf("iPod") !== -1
  )
    window.open(`maps://maps.google.com/maps?daddr=${address}&amp;ll=`);
  else window.open(`https://maps.google.com/maps?daddr=${address}&amp;ll=`);
}

export default mapsSelector;
