import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Scrollbar } from "react-scrollbars-custom";
import { isIE11, isSafari } from "utils";
import { isMobile } from "config";

import styleNormal from "./Filters.module.css";
import styleIE11 from "./FiltersIE11.module.css";

const style = isIE11 ? styleIE11 : styleNormal;

let debounceTimeout;
let initialFilters = true;

const Filters = ({
  filtersOpen,
  handleSetFilters,
  modelInfo: { model, modelCode },
  stores,
}) => {
  const [activeRetailersIds, setActiveRetailersIds] = useState([]);
  const [filterRetailers, setFilterRetailers] = useState([]);

  useEffect(() => {
    if (!stores) return; // todo
    if (stores.length) {
      const retailers = [];
      const retailersIds = [];
      for (let i = 0; i < stores.length; i++) {
        const store = stores[i];
        const { retailerId, retailerDisplayName, retailerName } = store;
        if (retailersIds.indexOf(retailerId) === -1) {
          retailers.push({ retailerId, retailerDisplayName, retailerName });
          retailersIds.push(retailerId);
        }
      }
      setFilterRetailers(retailers);
    }
  }, [stores]);

  useEffect(() => {
    if (debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      if (!initialFilters) {
        handleSetFilters(activeRetailersIds);
      } else {
        initialFilters = false;
      }
    }, 1000);
  }, [activeRetailersIds, handleSetFilters]);

  const handleCheckboxChange = (retailerId, event) => {
    const isChecked = event.target.checked;
    const currentRetailersIds = [...activeRetailersIds];
    const retailerIdIndex = currentRetailersIds.indexOf(retailerId);
    if (retailerIdIndex === -1 && isChecked) {
      currentRetailersIds.push(retailerId);
    } else if (retailerIdIndex >= 0 && !isChecked) {
      currentRetailersIds.splice(retailerIdIndex, 1);
    }
    setActiveRetailersIds(currentRetailersIds);
  };

  let filtersBodyClassName = `${style.filtersBody}`;
  if (filtersOpen) {
    filtersBodyClassName += ` ${style.open}`;
  }

  const scrollBarStyle =
    isSafari && !isMobile ? { height: "180px" } : { height: "85%" };

  return (
    <div className={filtersBodyClassName}>
      <span className={style.filtersTitle}>
        Wybierz preferowanego sprzedawcę
      </span>
      <ul id="RetailersFilter" className={`${style.retailersFilters}`}>
        <Scrollbar noDefaultStyles style={scrollBarStyle}>
          {filterRetailers.map((retailer) => {
            return (
              <li className={style.filterItem} key={`${retailer.retailerId}`}>
                <label className={style.retailerName}>
                  <span>
                    <input
                      data-omni-type="microsite_buyStore"
                      data-omni={`filter:${retailer.retailerName}|;${modelCode}|${model}`}
                      defaultChecked={
                        activeRetailersIds.indexOf(retailer.retailerId) >= 0
                      }
                      name={retailer.retailerDisplayName}
                      onChange={handleCheckboxChange.bind(
                        this,
                        retailer.retailerId
                      )}
                      tabIndex="0"
                      type="checkbox"
                    />
                  </span>
                  {retailer.retailerDisplayName}
                </label>
              </li>
            );
          })}
        </Scrollbar>
      </ul>
    </div>
  );
};

Filters.propTypes = {
  filtersOpen: PropTypes.bool,
  handleSetFilters: PropTypes.func,
  modelInfo: PropTypes.shape({
    model: PropTypes.string,
    modelCode: PropTypes.string,
  }).isRequired,
  stores: PropTypes.arrayOf(PropTypes.object),
};

export default Filters;
