import querystring from "querystring";

const { model } = querystring.parse(document.location.search.substring(1));

const isMobile = window.innerWidth < 770;

const googleMapsApiKey = "AIzaSyB0SXyRPHOxZ9Of5ibDKI39FACoJQ_A8g4";
const channeladvisorApiKey =
  "MQAyADAAMgA3ADIAOAAyAHwARABFAEYAQQBVAEwAVAB8AFoANQA4AFEASAA4AG4AdgB1AHgARABRAGwASABSAEQAQwBDADcATQA0AGEAbgBsAEoAcwB5AGkARABmAGwAWgBKAHgARABLAGEAdQBnAEcAUQBHAGYAMwBYAGoAQgB3AG8ARQBFAG0ARQA2ADUAdwBlAGYAZQBWAEQAUQBuAEcAVQByAHcANwBMAGIAegAxAG8ASgBnAGgASQB0ADkAbgBiAG0AUABCAFgAdwBDAHkAVABlAGIASABJAHAAVQBGADYANwB0AEMANABMAHcAMgBYAEcARQA9AHwA";

const hostname = window.location.host.split(":")[0]; // location.hostname is not supported on Opera browsers
const domain =
  hostname.split(".")[0] === "www" ? hostname.substring(4) : hostname;

let productionApiURL;

switch (domain) {
  case "where-to-buy.samsung.pl":
    productionApiURL = `https://wtbapi.samsung.pl/api/v1`;
    break;
  case "staging-where-to-buy.samsung.pl":
  default:
    productionApiURL = `https://staging-wtbapi.samsung.pl/api/v1`;
    break;
}

const CMS_BASE_URL = {
  development: `https://staging-wtbapi.samsung.pl/api/v1`,
  production: productionApiURL,
}[process.env.NODE_ENV];

const API_URLS = {
  CHANNELADVISOR: `https://productcatalog.channeladvisor.com/api/v1/offers/models/${model}`,
  SAMSUNG_CMS: {
    GET: `${CMS_BASE_URL}/orders-public/${model}`,
    POST: `${CMS_BASE_URL}/faults`,
  },
};

const defaultLocation = {
  lat: 52.2330653,
  lng: 20.9911518,
};

const range = 20;

export {
  API_URLS,
  channeladvisorApiKey,
  defaultLocation,
  googleMapsApiKey,
  isMobile,
  model,
  range,
};
