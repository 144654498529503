/* eslint no-continue: 0 */
import { getDistance } from "geolib";
import parseStoreHours from "./parseStoreHours";

const SORTING_TYPES = {
  custom: "custom",
  default: "default",
  none: "none",
};

const compareDistance = (a, b) =>
  a.distanceFromUserLocation - b.distanceFromUserLocation;

const isVisible = (store) => {
  const { hideGlobally, visible } = store;
  return hideGlobally !== "true" && visible !== "false";
};

const getSortingType = (cmsOnline = []) => {
  if (!cmsOnline[0]) return SORTING_TYPES.none;

  const { customOrder, defaultOrder } = cmsOnline[0];
  if (customOrder && customOrder !== "null") return SORTING_TYPES.custom;
  if (defaultOrder && defaultOrder !== "null") return SORTING_TYPES.default;

  return SORTING_TYPES.none;
};

const compareStoreOrder = (sortingType) => (a, b) => {
  // in case a store doesn't have order set by cms
  if (!a.correspondingCMSrecord) {
    if (!b.correspondingCMSrecord) return 0;
    return 1;
  }
  if (!b.correspondingCMSrecord) return -1;

  // otherwise sort according to cms order
  switch (sortingType) {
    case SORTING_TYPES.custom:
      return (
        parseInt(a.correspondingCMSrecord.customOrder, 10) -
        parseInt(b.correspondingCMSrecord.customOrder, 10)
      );
    case SORTING_TYPES.default:
      return (
        parseInt(a.correspondingCMSrecord.defaultOrder, 10) -
        parseInt(b.correspondingCMSrecord.defaultOrder, 10)
      );
    case SORTING_TYPES.none:
    default:
      return 0;
  }
};

const processData = (data, location, range, { isExtendedSearch } = {}) => {
  const [caData, cmsInitialData] = data;

  const cmsData = cmsInitialData || {
    onlineRetailers: [],
    offlineRetailers: [],
  };

  const modelInfo = {
    description: caData.description,
    model: caData.modelName,
    modelCode: caData.modelCode,
  };

  // divide cms stores into online and offline
  const {
    onlineRetailers: cmsOnline = [],
    offlineRetailers: cmsOffline = [],
  } = cmsData;

  // filter out not visible cms offline stores
  const filteredCmsOffline = cmsOffline.filter(isVisible);

  // set ca offline stores location from current user location when extended search
  if (isExtendedSearch) {
    caData.localRetailerStores.map((element) =>
      Object.assign(element, {
        distanceFromUserLocation: getDistance(element, location) / 1000,
      })
    );
  }

  // add to filteredCmsOffline calculated distances (in km) from currently chosen location
  // also map street to streetLine1
  filteredCmsOffline.map((element) =>
    Object.assign(element, {
      distanceFromUserLocation: getDistance(element, location) / 1000,
      latitude: Number(element.latitude),
      longitude: Number(element.longitude),
      streetLine1: element.street,
      retailerId: element.retailerId
        ? element.retailerId
        : element.retailerDisplayName,
    })
  );

  // data fields fix
  for (let i = 0; i < filteredCmsOffline.length; i++) {
    const retailer = filteredCmsOffline[i];
    // phone
    if (!retailer.phone && retailer.phoneNumber) {
      retailer.phone = retailer.phoneNumber;
    }
    // zip
    if (!retailer.postalCode && retailer.zip) {
      retailer.postalCode = retailer.zip;
    }
  }

  const tempLocalStores = {
    // get offline stores from visible ones and filter out those not within range
    cms: filteredCmsOffline.filter(
      ({ distanceFromUserLocation }) => distanceFromUserLocation <= range
    ),
    ca: caData.localRetailerStores,
  };

  const localStores = tempLocalStores.cms
    .concat(tempLocalStores.ca)
    .sort(compareDistance);

  const distanceFromNearestStore =
    isExtendedSearch && localStores.length
      ? localStores[0].distanceFromUserLocation
      : undefined;

  // get number of weekday for today, starting from Monday, not Sunday
  const todaysDayOfWeek = new Date().getDay() || 7;

  if (!isExtendedSearch) {
    localStores.map((localStore) => {
      const { hours } = localStore;
      const parsedHours = parseStoreHours(hours);
      const openToday = parsedHours && parsedHours[todaysDayOfWeek];

      return Object.assign(localStore, { openToday });
    });
  }

  const onlineStores = caData.onlineRetailers;

  // assign cms data for each related online store
  onlineStores.map((onlineStore) => {
    const { name, displayName } = onlineStore;
    const correspondingCMSrecord = cmsOnline.find(
      ({ retailerName }) =>
        retailerName.toLowerCase() === name.toLowerCase() ||
        retailerName.toLowerCase() === displayName.toLowerCase()
    );
    return Object.assign(onlineStore, { correspondingCMSrecord });
  });

  // filter out hidden online stores
  const filteredOnlineStores = onlineStores.filter(
    ({ correspondingCMSrecord }) => isVisible(correspondingCMSrecord)
  );

  // sort online stores according to cms order
  const sortingType = getSortingType(cmsOnline);
  filteredOnlineStores.sort(compareStoreOrder(sortingType));

  return {
    localStores: isExtendedSearch ? [] : localStores,
    wholeCountryLocalStores: isExtendedSearch ? localStores : [],
    modelInfo,
    distanceFromNearestStore,
    onlineStores: filteredOnlineStores,
  };
};

export default processData;
// ----------------------------------------------------------------------------------------------------------

// function ensureStoreTypes(stores) {
//   if (!stores) return [];
//   for (let i = 0; i < stores.length; i++) {
//     const store = stores[i];
//     // visible
//     if (typeof store.visible !== "boolean") {
//       store.visible = store.visible === "true";
//     }
//     // hideGlobally
//     if (typeof store.hideGlobally !== "boolean") {
//       store.hideGlobally = store.hideGlobally === "true";
//     }
//     // defaultOrder
//     if (typeof store.defaultOrder !== "number" && store.defaultOrder !== null) {
//       store.defaultOrder = Number(store.defaultOrder);
//     }
//     // customOrder
//     if (typeof store.customOrder !== "number" && store.customOrder !== null) {
//       store.customOrder = Number(store.customOrder);
//     }
//   }
//   return stores;
// }

// function findCmsRep(caStore, cmsStores) {
//   let idName = caStore.retailerName || caStore.displayName;
//   idName = idName.toLowerCase();

//   for (let i = 0; i < cmsStores.length; i++) {
//     const cmsStore = cmsStores[i];
//     const cmsStoreName1 = cmsStore.retailerName.toLowerCase();
//     if (cmsStoreName1 === idName) {
//       return cmsStore;
//     }
//   }
//   return false;
// }

// export function processDataOld(data) {
//   // data, location, range
//   const caData = data[0];
//   const cmsData = data[1];

//   const localStores = [];
//   const onlineStores = [];
//   const modelInfo = {
//     model: caData.modelName,
//     description: caData.description,
//   };

//   const caLocalStores = caData.localRetailerStores;
//   const caOnlineStores = caData.onlineRetailers;

//   const cmsLocalStores = ensureStoreTypes(cmsData.offlineRetailers);
//   const cmsOnlineStores = ensureStoreTypes(cmsData.onlineRetailers);

//   const errs = [];

//   const dayOfWeek = new Date().getDay() || 7;

//   // handle localStores
//   for (let i = 0; i < caLocalStores.length; i++) {
//     const localStore = caLocalStores[i];
//     const { retailerName } = localStore;
//     const cmsRep = findCmsRep(localStore, cmsLocalStores);
//     if (!cmsRep) {
//       errs.push(`cmsRep not found for local store ${retailerName}`);
//       continue;
//     }

//     // check if hidden / visible
//     if (cmsRep.hideGlobally || !cmsRep.visible) continue;
//     // user distance
//     // distanceFromUserLocation from ca or calc manually?

//     // open hours
//     localStore.openToday = parseStoreHours(localStore.hours)[dayOfWeek];

//     localStores.push(localStore);
//   }
//   // sort localStores
//   localStores.sort(
//     (a, b) => a.distanceFromUserLocation - b.distanceFromUserLocation
//   );

//   // handle online stores
//   let customSortOrderOnline = false;
//   for (let i = 0; i < caOnlineStores.length; i++) {
//     const onlineStore = caOnlineStores[i];
//     const cmsRep = findCmsRep(onlineStore, cmsOnlineStores);
//     if (!cmsRep) {
//       errs.push(`cmsRep not found for online store ${onlineStore.displayName}`);
//       continue;
//     }
//     // check if hidden / visible
//     if (cmsRep.hideGlobally || !cmsRep.visible) continue;
//     if (!customSortOrderOnline && cmsRep.customOrder !== null) {
//       customSortOrderOnline = true;
//     }

//     onlineStores.push(onlineStore);
//   }
//   // sort onlineStores
//   const sortingField = !customSortOrderOnline ? "defaultOrder" : "customOrder";
//   onlineStores.sort((a, b) => a[sortingField] - b[sortingField]);

//   return { localStores, onlineStores, modelInfo };
// }
