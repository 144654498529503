import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ReactComponent as DirectionsNormalIcon } from "assets/icons/round_directions.svg";
import { ReactComponent as DirectionsActiveIcon } from "assets/icons/round_directions_hover.svg";
import { ReactComponent as PhoneNormalIcon } from "assets/icons/round_phone.svg";
import { ReactComponent as PhoneActiveIcon } from "assets/icons/round_phone_hover.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock_icon.svg";
import { mapsSelector, parsePhoneNumber } from "utils";
import { isMobile } from "config";
import StoreHeaderSection from "./StoreHeaderSection";
import StoreAddressSection from "./StoreAddressSection";
import StoreFooterSection from "./StoreFooterSection";

import style from "./LocalRetailer.module.css";

const cx = classNames.bind(style);

const DirectionsIcon = ({
  isActive = false,
  city = "",
  modelInfo: { model, modelCode },
  retailerName,
  streetLine1 = "",
  streetLine2 = "",
}) => (
  <button
    className={cx(style.navigateToButton)} // , isActive && style.active
    data-omni-type="microsite_buyOnline"
    data-omni={`buy in store – google map:${retailerName}|;${modelCode}|${model}`}
    onClick={() => mapsSelector(`${city} ${streetLine1} ${streetLine2}`)}
    type="button"
  >
    {isActive ? <DirectionsActiveIcon /> : <DirectionsNormalIcon />}
  </button>
);

DirectionsIcon.propTypes = {
  city: PropTypes.string,
  isActive: PropTypes.bool,
  modelInfo: PropTypes.shape({
    model: PropTypes.string,
    modelCode: PropTypes.string,
  }).isRequired,
  retailerName: PropTypes.string.isRequired,
  streetLine1: PropTypes.string,
  streetLine2: PropTypes.string,
};

const PhoneIcon = ({ isActive = false }) =>
  isActive ? <PhoneActiveIcon /> : <PhoneNormalIcon />;

PhoneIcon.propTypes = {
  isActive: PropTypes.bool,
};

const LocalRetailer = ({
  handleClick,
  index,
  isActive = false,
  modelInfo,
  retailer: {
    city = "",
    distanceFromUserLocation = "",
    hours,
    openToday = "",
    phone = "",
    retailerDisplayName,
    retailerId = "",
    retailerName,
    streetLine1 = "",
    streetLine2 = "",
    postalCode = "",
  },
}) => {
  const isBrandStore = retailerId === "sklepsamsungpl_pl";
  const { model, modelCode } = modelInfo;

  if (isMobile) {
    return (
      <div
        className={cx(style.rowContainerMobile, isActive && style.active)}
        data-omni-type="microsite_buyOnline"
        data-omni={`buy in store:${retailerName}|;${modelCode}|${model}`}
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={0}
      >
        <div className={style.item}>
          <div
            className={cx(
              style.retailerMobile,
              isBrandStore && style.brandStoreDisplayName
            )}
          >
            {retailerDisplayName}
          </div>
          <div className={style.address}>
            <div>{city}</div>
            <div>{streetLine1}</div>
            <div>{streetLine2}</div>
          </div>
          {hours && (
            <div className={style.openHoursWrapper}>
              <ClockIcon />
              <span className={style.openHours}>
                {openToday
                  ? `Dzisiaj otwarte: ${openToday}`
                  : `Dzisiaj sklep nieczynny.`}
              </span>
            </div>
          )}
        </div>
        <div className={cx(style.iconWrapper, phone || style.renderBothIcons)}>
          {phone && (
            <a
              className={cx(style.phoneButton)}
              data-omni-type="microsite_buyOnline"
              data-omni={`buy in store – call to store:${retailerName}|;${modelCode}|${model}`}
              href={`tel:${phone}`}
            >
              <PhoneIcon isActive={isActive} />
            </a>
          )}
          <DirectionsIcon
            city={city}
            isActive={isActive}
            modelInfo={modelInfo}
            retailerName={retailerName}
            streetLine1={streetLine1}
            streetLine2={streetLine2}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx(style.rowContainer, isActive && style.active)}
      data-omni-type="microsite_buyOnline"
      data-omni={`buy in store:${retailerName}|;${modelCode}|${model}`}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      <div className={style.item}>
        <StoreHeaderSection
          isBrandStore={isBrandStore}
          index={index}
          retailerDisplayName={retailerDisplayName}
          distanceFromUserLocation={distanceFromUserLocation}
        />
        <StoreAddressSection
          streetLine1={streetLine1}
          streetLine2={streetLine2}
          city={city}
          postalCode={postalCode}
        />
        <StoreFooterSection
          hours={hours}
          modelInfo={modelInfo}
          openToday={openToday}
          phone={parsePhoneNumber(phone)}
          retailerName={retailerName}
        />
      </div>
      <DirectionsIcon
        city={city}
        isActive={isActive}
        modelInfo={modelInfo}
        retailerName={retailerName}
        streetLine1={streetLine1}
        streetLine2={streetLine2}
        postalCode={postalCode}
      />
    </div>
  );
};

LocalRetailer.propTypes = {
  handleClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  modelInfo: PropTypes.shape({
    model: PropTypes.string,
    modelCode: PropTypes.string,
  }).isRequired,
  retailer: PropTypes.shape({
    city: PropTypes.string,
    distanceFromUserLocation: PropTypes.number.isRequired,
    hours: PropTypes.string,
    openToday: PropTypes.string,
    phone: PropTypes.string,
    retailerDisplayName: PropTypes.string.isRequired,
    retailerName: PropTypes.string.isRequired,
    retailerId: PropTypes.string,
    streetLine1: PropTypes.string,
    streetLine2: PropTypes.string,
    postalCode: PropTypes.string,
  }).isRequired,
};

export default LocalRetailer;
