import camelcaseKeys from "camelcase-keys";

const mockedData1 = {
  OnlineRetailers: [
    {
      default_order: "4",
      custom_order: null,
      retailer_name: "MediaMarkt.pl",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "true",
      offline: "false",
      retailerDisplayName: "MediaMarkt.pl",
    },
    {
      default_order: "3",
      custom_order: null,
      retailer_name: "Media Expert",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "true",
      offline: "false",
      retailerDisplayName: "Media Expert",
    },
    {
      default_order: "2",
      custom_order: null,
      retailer_name: "Neonet",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "true",
      offline: "false",
      retailerDisplayName: "Neonet",
    },
    {
      default_order: "5",
      custom_order: null,
      retailer_name: "Komputronik",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "true",
      offline: "false",
      retailerDisplayName: "Komputronik",
    },
    {
      default_order: "9",
      custom_order: null,
      retailer_name: "Euro.com.pl",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "true",
      offline: "false",
      retailerDisplayName: "Euro.com.pl",
    },
    {
      default_order: "11",
      custom_order: null,
      retailer_name: "Vobis.pl",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "true",
      offline: "false",
      retailerDisplayName: "Vobis.pl",
    },
    {
      default_order: "10",
      custom_order: null,
      retailer_name: "X-kom.pl",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "true",
      offline: "false",
      retailerDisplayName: "X-kom.pl",
    },
    {
      default_order: "1",
      custom_order: null,
      retailer_name: "T-mobile",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "true",
      offline: "false",
      retailerDisplayName: "T-mobile",
    },
    {
      default_order: "6",
      custom_order: null,
      retailer_name: "Orange",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "true",
      offline: "false",
      retailerDisplayName: "Orange",
    },
    {
      default_order: "8",
      custom_order: null,
      retailer_name: "Play",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "true",
      offline: "false",
      retailerDisplayName: "Play",
    },
    {
      default_order: "7",
      custom_order: null,
      retailer_name: "Plus",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "true",
      offline: "false",
      retailerDisplayName: "Plus",
    },
  ],
  OfflineRetailers: [
    {
      default_order: null,
      custom_order: null,
      retailer_name: "Cheil",
      phone_number: "123456789",
      cta: "",
      alert_text: null,
      website_url: "",
      logo_url: "/media/logos/VRv9CskxY8OJqnMnyKhHcKhSl3I8FVFEirJ1vzCX.png",
      street: "marynarska 15",
      zip: "02-674",
      city: "Warszawa",
      addressConfirm: "1",
      hours:
        '{"1":"08:00-22:00","2":"08:00-22:00","3":"08:00-22:00","4":"08:00-22:00","5":"08:00-22:00","6":"Nieczynne","7":"Nieczynne"}',
      hide_globally: "false",
      visible: "true",
      offline: "true",
      latitude: "52.1781619",
      longitude: "20.9989029",
      retailerDisplayName: "Cheil",
    },
    {
      default_order: null,
      custom_order: null,
      retailer_name: "test2",
      phone_number: "123456789",
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: "/media/logos/FCefRtKt2na1tuXE4DAL6gYJa4G0vPuv6evY1DSy.png",
      street: "Tamka 1",
      zip: "00-950",
      city: "Warszawa",
      addressConfirm: "1",
      hours:
        '{"1":"08:00-22:00","2":"08:00-22:00","3":"08:00-22:00","4":"08:00-22:00","5":"08:00-22:00","6":"Nieczynne","7":"Nieczynne"}',
      hide_globally: "false",
      visible: "false",
      offline: "true",
      latitude: "52.2388495",
      longitude: "21.0295903",
      retailerDisplayName: "test2",
    },
    {
      default_order: null,
      custom_order: null,
      retailer_name: "Cheil 2",
      phone_number: "987654321",
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: "/media/logos/W6ihx4n5mf6AWrXiYkKBsZcbYRMeRatCMAqF5zev.png",
      street: "Postępu 10",
      zip: "00-000",
      city: "warszawa",
      addressConfirm: "1",
      hours:
        '{"1":"08:00-22:00","2":"08:00-22:00","3":"08:00-22:00","4":"08:00-22:00","5":"08:00-22:00","6":"08:00-22:00","7":"Nieczynne"}',
      hide_globally: "false",
      visible: "true",
      offline: "true",
      latitude: "52.17535789999999",
      longitude: "20.9953197",
      retailerDisplayName: "Cheil 2",
    },
  ],
};

const mockedData2 = {
  OnlineRetailers: [
    {
      default_order: "1",
      custom_order: null,
      retailer_name: "MediaMarkt.pl",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "false",
      offline: "false",
      retailerDisplayName: "MediaMarkt.pl",
    },
    {
      default_order: "3",
      custom_order: null,
      retailer_name: "Media Expert",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "false",
      offline: "false",
      retailerDisplayName: "Media Expert",
    },
    {
      default_order: "2",
      custom_order: null,
      retailer_name: "Neonet",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "false",
      offline: "false",
      retailerDisplayName: "Neonet",
    },
    {
      default_order: "4",
      custom_order: null,
      retailer_name: "Euro.com.pl",
      phone_number: null,
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: null,
      street: "",
      zip: "",
      city: "",
      addressConfirm: "0",
      hours: "",
      hide_globally: "false",
      visible: "false",
      offline: "false",
      retailerDisplayName: "Euro.com.pl",
    },
  ],
  OfflineRetailers: [
    {
      default_order: null,
      custom_order: null,
      retailer_name: "Cheil",
      phone_number: "123456789",
      cta: "",
      alert_text: null,
      website_url: "",
      logo_url: "/media/logos/VRv9CskxY8OJqnMnyKhHcKhSl3I8FVFEirJ1vzCX.png",
      street: "marynarska 15",
      zip: "02-674",
      city: "Warszawa",
      addressConfirm: "1",
      hours:
        '{"1":"08:00-22:00","2":"08:00-22:00","3":"08:00-22:00","4":"08:00-22:00","5":"08:00-22:00","6":"08:00-22:00","7":"Nieczynne"}',
      hide_globally: "false",
      visible: "false",
      offline: "true",
      latitude: "52.1781619",
      longitude: "20.9989029",
      retailerDisplayName: "Cheil",
    },
    {
      default_order: null,
      custom_order: null,
      retailer_name: "test2",
      phone_number: "123456789",
      cta: "",
      alert_text: null,
      website_url: "",
      logo_url: "/media/logos/FCefRtKt2na1tuXE4DAL6gYJa4G0vPuv6evY1DSy.png",
      street: "Tamka 1",
      zip: "00-950",
      city: "Warszawa",
      addressConfirm: "1",
      hours:
        '{"1":"00:00-22:30","2":"00:00-22:30","3":"00:00-22:30","4":"00:00-22:30","5":"00:00-22:30","6":"00:00-22:30","7":"00:00-22:30"}',
      hide_globally: "false",
      visible: "false",
      offline: "true",
      latitude: "52.2388495",
      longitude: "21.0295903",
      retailerDisplayName: "test2",
    },
    {
      default_order: null,
      custom_order: null,
      retailer_name: "Cheil 2",
      phone_number: "9876543217777773737763663432324342342",
      cta: "",
      alert_text: null,
      website_url: "",
      logo_url: "/media/logos/W6ihx4n5mf6AWrXiYkKBsZcbYRMeRatCMAqF5zev.png",
      street:
        "Rondo Kombatant\u00f3w Rzeczypospolitej Polskiej i By\u0142ych Wi\u0119\u017ani\u00f3w Politycznych 15",
      zip: "00-000",
      city: "warszawa",
      addressConfirm: "1",
      hours:
        '{"1":"09:00-17:30","2":"09:00-17:30","3":"09:00-17:30","4":"09:00-17:30","5":"09:00-17:30","6":"Nieczynne","7":"Nieczynne"}',
      hide_globally: "false",
      visible: "false",
      offline: "true",
      latitude: "52.17535789999999",
      longitude: "20.9953197",
      retailerDisplayName: "Cheil 2",
    },
    {
      default_order: null,
      custom_order: null,
      retailer_name: "Fler",
      phone_number: "600600600",
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: "/media/logos/scPMZytap4bbO5lfSXxwtRzdPon5RL7ghEScnaTN.jpg",
      street: "Zr\u0119czyce 186a",
      zip: "32-420",
      city: "Gd\u00f3w",
      addressConfirm: "1",
      hours:
        '{"1":"09:00-17:00","2":"09:00-17:00","3":"09:00-17:00","4":"09:00-17:00","5":"09:00-17:00","6":"10:00-15:00","7":"Nieczynne"}',
      hide_globally: "false",
      visible: "true",
      offline: "true",
      latitude: "49.8969442",
      longitude: "20.2131548",
      retailerDisplayName: "Fler",
    },
    {
      default_order: null,
      custom_order: null,
      retailer_name: "Guzowski",
      phone_number: "48600600600",
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: "/media/logos/0zgHjaSPbWAYmN6sMrAa0A2qAGpGjMhnkuUHf57L.jpg",
      street: "Krzywaczka 758",
      zip: "32-442",
      city: "Krzywaczka",
      addressConfirm: "1",
      hours:
        '{"1":"08:30-17:30","2":"08:30-17:30","3":"08:30-17:30","4":"08:30-17:30","5":"08:30-17:30","6":"10:00-15:00","7":"Nieczynne"}',
      hide_globally: "false",
      visible: "true",
      offline: "true",
      latitude: "49.8992619",
      longitude: "19.840673",
      retailerDisplayName: "Guzowski",
    },
    {
      default_order: null,
      custom_order: null,
      retailer_name: "\u015al\u0105skie Kuchnie",
      phone_number: "48600600600",
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: "/media/logos/gf6Y6hNoWN17Mdr5xWXMFl4mts75aPTQ0Op6R9Ly.jpg",
      street: "O\u015bwi\u0119cimska 86",
      zip: "41-949",
      city: "Piekary \u015al\u0105skie",
      addressConfirm: "1",
      hours:
        '{"1":"09:00-17:30","2":"09:00-17:30","3":"09:00-17:30","4":"09:00-17:30","5":"09:00-17:30","6":"10:00-17:00","7":"Nieczynne"}',
      hide_globally: "false",
      visible: "true",
      offline: "true",
      latitude: "50.3670444",
      longitude: "18.9783643",
      retailerDisplayName: "\u015al\u0105skie Kuchnie",
    },
    {
      default_order: null,
      custom_order: null,
      retailer_name: "Jurimex",
      phone_number: "48600600600",
      cta: null,
      alert_text: null,
      website_url: null,
      logo_url: "/media/logos/WIDFWZ3CAtuqdp6RHKsYAcx7TkHuymnlci3nsvPh.jpg",
      street: "\u015aw.Rocha 66",
      zip: "42-200",
      city: "Cz\u0119stochowa",
      addressConfirm: "1",
      hours:
        '{"1":"09:30-17:30","2":"09:30-17:30","3":"09:30-17:30","4":"09:30-17:30","5":"09:30-17:30","6":"10:30-16:00","7":"Nieczynne"}',
      hide_globally: "false",
      visible: "true",
      offline: "true",
      latitude: "50.819042",
      longitude: "19.0856785",
      retailerDisplayName: "Jurimex",
    },
  ],
};

export default camelcaseKeys(
  process.env.REACT_APP_MOCK_EXTENDED ? mockedData2 : mockedData1,
  { deep: true }
);
