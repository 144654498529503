import React from "react";
import PropTypes from "prop-types";
import style from "./StoreAddressSection.module.css";

const StoreAddressSection = ({
  streetLine1,
  streetLine2,
  city,
  postalCode,
}) => (
  <div className={style.address}>
    <div>{streetLine1}</div>
    {streetLine2 && <div>{streetLine2}</div>}
    <div>
      {postalCode ? `${postalCode}` : ""} {city}
    </div>
  </div>
);

StoreAddressSection.propTypes = {
  city: PropTypes.string,
  streetLine1: PropTypes.string,
  streetLine2: PropTypes.string,
  postalCode: PropTypes.string,
};

export default StoreAddressSection;
