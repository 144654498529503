import { useEffect } from "react";
import PropTypes from "prop-types";
import { fetchData } from "api";
import { model } from "config";

const DataFetcher = ({
  isInitialFetch = false,
  location,
  range,
  setFetchedData,
}) => {
  useEffect(() => {
    if (isInitialFetch) setFetchedData({ modelInfo: { model } });
    fetchData(location, range).then((data) => {
      setFetchedData({ ...data, isLoadingComplete: true });
    });
  }, [location, range, setFetchedData, isInitialFetch]);

  return null;
};

DataFetcher.propTypes = {
  isInitialFetch: PropTypes.bool,
  location: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
  range: PropTypes.number,
  setFetchedData: PropTypes.func.isRequired,
};

export default DataFetcher;
