import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as ClockIcon } from "assets/icons/clock_icon.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/phone_icon.svg";

import style from "./StoreFooterSection.module.css";

const StoreFooterSection = ({
  hours,
  modelInfo: { model, modelCode } = {},
  openToday,
  phone,
  retailerName,
}) => (
  <div className={style.storeFooter}>
    {hours && (
      <div className={style.elementFooter}>
        <ClockIcon />
        <span>
          {openToday
            ? `Dzisiaj otwarte: ${openToday}`
            : `Dzisiaj sklep nieczynny.`}
        </span>
      </div>
    )}
    {phone && (
      <a
        className={style.elementFooter}
        data-omni-type="microsite_buyOnline"
        data-omni={`buy in store – call to store:${retailerName}|;${modelCode}|${model}`}
        href={`tel:${phone}`}
      >
        <PhoneIcon />
        <span>{phone}</span>
      </a>
    )}
  </div>
);

StoreFooterSection.propTypes = {
  hours: PropTypes.string,
  modelInfo: PropTypes.shape({
    model: PropTypes.string,
    modelCode: PropTypes.string,
  }),
  openToday: PropTypes.string,
  phone: PropTypes.string,
  retailerName: PropTypes.string,
};

export default StoreFooterSection;
