import { getData, postData, processData } from "utils";
import { API_URLS, channeladvisorApiKey, defaultLocation, model } from "config";

import mockedData from "./mockedData";

const postEmptyResultInfo = async () =>
  postData(API_URLS.SAMSUNG_CMS.POST, undefined, {
    sku: model,
  });

const fetchCA = async ({ lat, lng }, range) => {
  const MAX_RANGE = 1600;
  const queryRange = range <= MAX_RANGE ? range : MAX_RANGE;
  const queryParams = `?Latitude=${lat}&Longitude=${lng}&distanceRange=${queryRange}&distanceUnit=Kilometers`;
  const queryParamsUrl = `${API_URLS.CHANNELADVISOR}${queryParams}`;

  return getData(queryParamsUrl, {
    authorization: `api-key ${channeladvisorApiKey}`,
  });
};

const fetchCMS = async () => getData(API_URLS.SAMSUNG_CMS.GET);

const fetchData = async (location = defaultLocation, range = 20) => {
  try {
    const fetchedData = await Promise.all([
      fetchCA(location, range),
      process.env.REACT_APP_MOCK ? mockedData : fetchCMS(),
    ]);

    // send information about receiving empty array of online stores from channeladvisor
    if (!fetchedData[0].onlineRetailers.length) postEmptyResultInfo();

    let processedData = processData(fetchedData, location, range);

    // search within 1000km range (entire country) if no local stores found
    if (!processedData.localStores.length) {
      const fetchedCA = await fetchCA(defaultLocation, 700);

      // process the data as extendedSearching (return the distance to closest local store and stores fetched for default location - as wholeCountryLocalStores)
      processedData = processData(
        [fetchedCA, fetchedData[1]],
        location,
        range,
        { isExtendedSearch: true }
      );
    }

    return processedData;
  } catch (error) {
    if (process.env.NODE_ENV !== "production") console.error("error", error);
  }

  return {
    onlineStores: [],
    localStores: [],
    modelInfo: { model },
  };
};

export { fetchData, postEmptyResultInfo };
