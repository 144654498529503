import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ReactComponent as UseLocationIcon } from "assets/icons/use_location.svg";
import { isMobile } from "config";
import style from "./FindInMyLocation.module.css";

const cx = classNames.bind(style);

const FindInMyLocation = ({
  modelInfo: { model, modelCode },
  triggerUserLocation,
}) => (
  <button
    className={cx(
      isMobile ? style.localizationMobile : style.localizationDesktop
    )}
    data-omni-type="microsite_buyStore"
    data-omni={`findMyLocation|;${modelCode}|${model}`}
    onClick={triggerUserLocation}
    type="button"
  >
    <UseLocationIcon />
    <span className={style.localizationText}>ZNAJDŻ W MOJEJ LOKALIZACJI</span>
  </button>
);

FindInMyLocation.propTypes = {
  modelInfo: PropTypes.shape({
    model: PropTypes.string,
    modelCode: PropTypes.string,
  }).isRequired,
  triggerUserLocation: PropTypes.func.isRequired,
};

export default FindInMyLocation;
