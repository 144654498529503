import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import style from "./Switch.module.css";

const cx = classNames.bind(style);

const Switch = ({ disabled = false, handleClick, isStorePicked, title }) => (
  <button
    className={cx(style.switchWrapper, isStorePicked && style.storePicked)}
    disabled={disabled}
    onClick={handleClick}
    type="button"
  >
    <span className={style.title}>{title}</span>
  </button>
);
Switch.propTypes = {
  disabled: PropTypes.bool,
  isStorePicked: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default Switch;
